import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Section } from '../content';

import styled from 'styled-components';
import BGTop from '../../../assets/bg-section2.jpg';
import BGBtm from '../../../assets/bg-btm-section2.jpg';
import AsianWomen from '../../../assets/Woman.png';

const ShinglesFAQ = styled.div`
    width:100%;
    padding-top:30px;
    background:url(${BGTop}) top repeat-x #d8d8d8;
    h1{
      font-size:4em;
      text-align:center;
      color:#151818;
      padding-bottom:30px;
    }
    #cardWrapper{
       width:90%;
       margin:0px auto;
       sup{
         font-size:0.5em;
       }
       @media (min-width: 600px) {
         width:70%; 
       }
       .card{
          width:100%;
          border-radius:10px;
          padding:10px;
          box-sizing:border-box;
          background:#fff;
          margin-bottom:25px;
          @media (min-width: 600px) {
            background:#eaeaea;
            border-radius:15px;
            padding:20px;
          }
          .header{
              display:flex;
              justify-content:space-between;
              align-items:center;
              cursor:pointer;
              h2{
                font-size:1.5em;
                color:#151818;
                text-transform:uppercase;
                @media (min-width: 600px) {
                  width:70%; 
                  font-size:1.7em;
                  br{
                    display:none;
                  }
                }
              }
          }
          .content{
            padding-bottom:20px;
            display:block;
              p{
                color:#151818;
                font-size:1.1em;
                line-height:1.5em;
                padding-top:15px;
                font-weight:500;
                @media (min-width: 600px) {
                  font-size:1.3em;
                }
              }
              ul{
                padding:0;
                margin:0;
                color:#151818;
                font-size:1.1em;
                line-height:1.5em;
                @media (min-width: 600px) {
                  font-size:1.3em;
                }
                 li{
                   margin:0;
                   padding:0;
                   list-style-position: inside;
                 }
              }
              &.show{
                display:block;
              }
            
          }
       }
    }
    #knowTheFacts{
        width:100%;
        display:flex;
        flex-direction:column-reverse;
        padding-top:3vh;
        background:url(${BGBtm}) bottom repeat-x;     
        @media (min-width: 768px) {
          flex-direction:row;
        }
        #asianWomen{
          img{
            width:100%;
            height:auto;
          }
        }
        #content{
          width:100%;
          text-transform:uppercase;
          display:flex;
          flex-direction:column;
          justify-content:center;
          text-align:center;
          padding-bottom:20px;
          @media (min-width: 768px) {
            text-align:left;
            padding:0;
            width:50%;
            align-items:center;
            /* span{
              padding-left:50px;
            } */
          }
          @media (min-width: 992px) {
            span{
              padding-left:50px;
            }
          }
          h1{
            font-size:4em;
            color:#151818;
            letter-spacing:5px;
            font-weight:bold;
            @media (min-width: 600px) {
              font-size:4.5em;
              font-weight:bold;
            }
            @media (min-width: 768px) {
              font-size:4em;
              font-weight:bold;
              text-align:center
            }
            @media (min-width: 992px) {
              font-size:6em;
              font-weight:bold;
              text-align:center
            }
            @media (min-width: 1200px) {
              font-size:6em;
              font-weight:bold;
              text-align:left
            }

          }
          dl,dt,dd{
            margin:0px;
            padding:0px;
            font-size:1.2em;
          }
          dd,dt{
            @media (min-width: 600px) {
              font-size:1.3em;
            }
            @media (min-width: 768px) {
              font-size:1.2em;
              line-height:1.5em;
              text-align:center;
           }
            @media (min-width: 992px) {
              font-size:1.5em;           
              text-align:left;
          }
          }
          dd{
            @media (min-width: 768px) {
              padding-left:0px;
            }
            @media (min-width: 992px) {
              padding-left:20px;
            }
          }
          dl{
            letter-spacing:3px;
            line-height:1.9em;
          }
          dt.topSpace{
           margin-top:20px;
           @media (min-width: 768px) {
              margin-top:0
           }
          }
         
       }
    }
`
const Icon = styled.div`
        width:26px;
        height:26px;
        display:flex;
        align-items:center;
        justify-content:center;
        flex-direction:column;
        cursor:pointer;
        &::before{
          content:"";
          width:26px;
          height:4px;
          background:#151818;
          border-radius:5px;
        }
        &::after{
          content:"";
          width:26px;
          height:4px;
          background:#151818;
          margin-top:-4px;
          border-radius:5px;
          transform: rotate(90deg);
        }

        @media (min-width: 600px) {
          width:36px;
          height:36px;
          
          &::before,&::after{
            width:36px;
          }

        }



        &.minus{
          &::after{
            display:none;
          }
        }
   `

/*    @media (min-width: 600px) {
    font-size:4.7vw;
    br{
      display:none;
    }
  } */

const Card = (props) => {
  const item = props.data;
  const index = props.index;
  return (
    <div className="card">
      <div className="header" onClick={() => props.onShowFaqItem(item, index)}>
        <h2>{item.title}</h2>
        <Icon className={['faq'+ index, item.active ? 'dtaccordionclick minus ' : "dtaccordionclick"].join(' ')}  
         data-accordionname = {item.analyticsTitle} data-accordionstatus = {item.active ? "expand" : "collapse"}
        />        
      </div>
      {
        item.active ? <div className="content" >
          {item.content}
        </div> : ''
      }

    </div>
  )
}



export const Section4 = () => {
  const { t } = useTranslation('common');

  const FAQ = [
    {
      title: <>What is <strong>shingles?</strong></>,
      analyticsTitle: "What is shingles?",
      content:
        <>
          <p>Shingles is a painful rash caused by the reactivation of the varicella zoster virus (the same virus that causes chickenpox).<sup>3</sup></p>
          <p>Most adults have had chickenpox as a child putting them at risk of shingles later in life<sup>4,6</sup></p>
          <p>The chickenpox virus remains dormant in the body. It can be reactivated later and cause shingles in people over 50 years of age or with a weakened immune system.<sup>3,5</sup></p>
          <p>There is a 1 in 3 lifetime risk of the dormant varicella zoster virus reactivating and causing shingles<sup>1,2</sup></p>
        </>,
      active: true
    },
    {
      title: <>What are <br/><strong>typical symptoms?</strong></>,
      analyticsTitle: "What are typical symptoms?",
      content:
        <>
          <p>The first signs of shingles can be a tingling or painful feeling in an area of skin, a headache or feeling generally unwell. Typically, a blistering rash may appear a few days later on one side of the body only.<sup>3</sup></p>
          <p>Usually, you get the shingles rash on your chest and abdomen, but it can appear anywhere on your body including on your face.<sup>3</sup></p>
          <p>It can take up to 4 weeks for the rash to heal.<sup>3</sup></p>
          <p>Your skin can be painful for weeks after the rash has gone, but it usually gets better over time.<sup>3</sup></p>
          <p>If you are experiencing any of these symptoms, please speak with your pharmacist, doctor or practice nurse.</p>
        </>,
      active: false
    },
    {
      title: <>Why am I at<br/><strong> higher risk after age 50?</strong></>,
      analyticsTitle:"Why am I at higher risk after age 50?",
      content:
        <>
          <p>As the immune system naturally weakens over time as you age. This natural decline in immunity leads to an increased risk of shingles no matter how healthy you may feel.</p>
          <p>Older adults are also at increased risk of complications such as post-herpetic neuralgia (PHN).<sup>7</sup> </p>
        </>,
      active: false
    },
    {
      title: <>Am I still at risk if <br/><strong>I haven't had chickenpox?</strong></>,
      analyticsTitle:"Am I still at risk if I haven't had chickenpox?",
      content:
        <>
          <p>If you’ve never had chickenpox, you are not at risk of shingles but remain at risk of chickenpox. However, you may have been exposed to the virus without knowing, or you may not remember having chickenpox, which means you may be at risk of developing shingles. </p>
          <p>You cannot get shingles from someone with shingles or chickenpox. But you can get chickenpox from someone with shingles if you haven't had chickenpox before.<sup>3</sup></p>
        </>,
      active: false
    },
    {
      title: <>Is <strong>shingles</strong><br/>  contagious?</>,
      analyticsTitle:"Is shingles contagious?",
      content:
        <>
          <p>Shingles occurs when the virus that’s already in your body reactivates</p>
          <p>You cannot get shingles from someone with shingles or chickenpox.<br/> But you can get chickenpox from someone with shingles if you’ve never had chickenpox.<sup>3</sup></p>
        </>,
      active: false
    },
    {
      title: <>CAN SHINGLES BE  <br/><strong>TREATED OR PREVENTED?</strong></>,
      analyticsTitle:"Can shingles be treated or prevented",
      content:
        <>
          <p><strong>Treatment/Management:<sup>3</sup></strong><br />
            If you think you may have shingles, please speak with your doctor as soon as possible. They may prescribe appropriate medicines to help reduce the severity and duration of your symptoms.
          </p>
          <p><strong>General advice for managing symptoms:</strong></p>
          <ul>
            <li>Keep the rash clean and dry to reduce the risk of infection</li>
            <li>Wear loose-fitting clothing</li>
            <li>Use a cool compress a few times a day</li>
          </ul>
          <p><strong>Prevention:</strong><br />
            Shingles can be prevented through vaccination<sup>3</sup>. Speak with your pharmacist, practice nurse or doctor to discuss prevention options.
          </p>
        </>,
      active: false
    },
    {
      title: <>What do I do if  <br/><strong>I THINK I MAY have shingles?</strong></>,
      analyticsTitle:"What do I do if I think I may have shingles",
      content:
        <>
          <p>Contact your doctor as soon as possible if you think you may have shingles. Your pharmacist, doctor or practice nurse will also be able to advise you on how to manage any symptoms you may be experiencing.</p>
        </>,
      active: false
    }
  ]

  const [getFaqItems, setFaqItems] = useState(FAQ)




  const onShowFaqItem = (item, index) => {
    const muteGetFaqItems = [...getFaqItems];
    muteGetFaqItems[index].active = !muteGetFaqItems[index].active;
    setFaqItems(muteGetFaqItems);
  };

  return (
    <Section id="section-four">
      <ShinglesFAQ>
        <h1><strong>SHINGLES</strong> FAQ</h1>
        <div id="cardWrapper">
          {
            getFaqItems.map((item, index) => {
              return <Card data={item} key={index} index={index} onShowFaqItem={onShowFaqItem} />
            })
          }
        </div>
        <div id="knowTheFacts">
          <div id="asianWomen">
            <img src={AsianWomen} alt="woman at risk of shingles" title="woman at risk of shingles"/>
          </div>
          <div id="content">
            <h1>KNOW <br /><span>THE FACTS</span></h1>
            <dl>
              <dt>DON'T LET <strong>SHINGLES</strong> AFFECT </dt>
              <dd>YOUR QUALITY OF LIFE.</dd>
              <dt className="topSpace" style={{'fontWeight':'bold'}}>TALK TO YOUR PHARMACIST,</dt>
              <dd style={{'fontWeight':'bold'}}>PRACTICE NURSE OR DOCTOR</dd>
              <dd style={{'fontWeight':'bold', 'marginLeft':'20px' }}>FOR MORE INFORMATION.</dd>
            </dl>
            
          </div>
        </div>
      </ShinglesFAQ>
    </Section>
  );
};
