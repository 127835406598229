import { useTranslation } from 'react-i18next';
import { Section } from '../content';
import styled from 'styled-components';
import bg from '../../../assets/bg-section2.jpg';
import virus from '../../../assets/virus.png';
import doctor from '../../../assets/doctor.png';
import risk from '../../../assets/risk.png';
import plus from '../../../assets/plus.png';

const WhyYouNeedToKnow = styled.div`
   width:100vw;
   background:url(${bg}) repeat-x;
   padding-top:7vh;
   padding-bottom:7vh;
   display:flex;
   justify-content: center;
     #wrapper{
         width:90vw;
         @media (min-width: 600px) {
          width:80vw;
          br{
          /*   display:none */
          }
         }
         h1{
          color:#151818;
          font-size:3em;
          letter-spacing:2px;
          font-weight:normal;
          text-transform:uppercase;
          text-align :center;
          strong{
            font-weight:bold;
          }
          @media (min-width: 600px) {
            font-size:4em;
            br{
              display:none;
            }
          }
         }
         .sectionWrapper{
            width:90vw;
            padding-top:5vh;
            @media (min-width: 600px) {
              width:80vw;
            }
             .section{
                width:calc(90vw - 30px);
                padding: 70px 15px 40px 15px;
                border-radius :15px;
                background:#fff;  
                margin-top:-68px;
                @media (min-width: 600px) {
                  width:calc(90vw - 60px);
                  padding: 70px 25px 40px 25px;
                }
                h1{
                  text-align :center;
                  font-size:3em;
                  font-weight:bold;
                  padding-bottom:20px;
                }
                p{
                  color:#151818;
                  font-size:1.3em;
                 
                  line-height:1.5em;
                   &.small{
                     font-size:0.9em;
                     padding-top:20px;
                     padding-bottom:0px;
                   }
                   &.spaceBtm{
                      padding-bottom:20px;
                   }
                }
                p.upperCase{
                  text-transform:uppercase;
                }
                ul{  
                  margin:5px 0 0 -17px;
                  &.inset{
                    margin-left:0px;
                    padding-bottom:20px;
                  }
                  li{
                    margin-left:0;
                    padding:0;
                    padding-bottom:10px;
                    list-style-position: outside;  
                    color:#151818;
                    font-size:1.3em;
                    &:last-child{
                      padding-bottom:0;
                    }
                  }
                  
                }
                sup{
                   font-size:0.6em;
                }
                @media (min-width: 600px) {
                  width:calc(80vw - 120px);
                  border-radius :25px;
                  padding: 60px 60px 40px 60px;
                  h1{
                    text-align:left;
                  }
                }
             }
         }

     }
`

    const Icon = styled.div`
      /* display:flex;
      justify-content:center; */
      text-align:center;
     /*  position:relative;
      z-index:100; */
      img{
          border-radius:100px;
          border:12px solid white;
          background:#fff;
          width:96px;
          height:96px;
         /*  margin:0px auto;
          position:absolute; */
          @media (min-width: 600px) {
            border-radius:100px;
            border:15px solid white;   
          }
      }
    `

export const Section1 = () => {
  const { t } = useTranslation('common');
  return (
    <Section id="section-one" className="section-one">
            <WhyYouNeedToKnow>
                 <div id="wrapper">
                     <h1>WHAT you <br /><strong>need to know:</strong></h1>
                      <div className="sectionWrapper">
                             <Icon><img src= {virus} alt="about the virus that causes shingles " title="about the virus that causes shingles " /></Icon>
                            <div className="section">
                                 <h1>SHINGLES</h1>
                                 <p className="upperCase spaceBtm">ALSO KNOWN AS <strong>HERPES ZOSTER</strong>, IS<strong> a disease that presents as a painful rash.<sup>3</sup></strong></p>
                                 <p className="spaceBtm">Shingles is caused by the reactivation of the varicella zoster virus, the same virus that causes chickenpox. After a person gets chickenpox, the virus stays dormant inside the body for life, usually not causing any problems or symptoms. Our immune system naturally weakens over time as we age which may allow the usually inactive virus to reactivate, causing shingles.<sup>4</sup> </p>
                                 <ul>
                                   <li>The first signs of shingles can be a tingling or painful feeling in an area of skin, a headache or feeling generally unwell</li>
                                   <li>Typically, a blistering rash may appear a few days later, on one side of the body only</li>
                                   <li>The shingles rash most commonly appears on the chest or abdomen, but can appear anywhere on the body including the face</li>
                                   <li>It can take up to 4 weeks for the rash to heal.  Usually, symptoms improve within weeks, however some patients suffer prolonged pain or other complications<sup>4</sup></li>
                                 </ul>
                            </div>
                      </div>
                       <div className="sectionWrapper">
                             <Icon><img src= {risk} alt="who is at risk of shingles" title="who is at risk of shingles"/></Icon>
                             <div className="section">
                               <h1>WHO'S AT RISK?</h1>
                               <ul>
                                <li>Shingles is <strong>most common in people over 50 years of age,</strong> and those with a weakened immune system <sup>5</sup></li>
                                <li>Most adults have had chickenpox as a child putting them at risk of shingles later in life<sup>4,6</sup></li>
                                <li>There is a 1 in 3 lifetime risk of the dormant varicella zoster virus reactivating and causing shingles<sup>1,2</sup></li>
                                <li>Reactivation of the varicella zoster virus can cause shingles, regardless of how healthy a person may feel<sup>7</sup></li>
                               </ul>
                             </div>
                      </div>
                      <div className="sectionWrapper">
                             <Icon><img src= {doctor} alt="possible complications of shingles" title="possible complications of shingles" /></Icon>
                            <div className="section">
                                <h1>Possible complications of shingles </h1>
                                <p className="spaceBtm">While most people recover fully, some people may experience complications. The most common complication of shingles is <strong>post-herpetic neuralgia (PHN)</strong> which is experienced as nerve pain that persists after the shingles rash has healed. PHN can last for three to six months but can persist for longer. It can be more common and more severe in older people than in younger people.<sup>4</sup> After the age of 50 years, about 20% of patients with shingles will develop post-herpetic neuralgia (PHN).<sup>7</sup></p>
                                <p><strong>Other complications may also include<sup>8</sup>:</strong> </p>
                                <ul className="inset">
                                  <li>Skin changes – scarring or changes in pigmentation after the rash has healed</li>
                                  <li>Secondary infection of the shingles rash</li>
                                  <li>Herpes Zoster Oticus – occurs when the virus infects the facial nerve</li>
                                  <li>Herpes Zoster Ophthalmicus – occurs when the virus infects affected nerves around the eye</li>
                                  <li>Peripheral and central nervous system complications</li>
                                  <li>Cardiovascular complications</li>
                                  <li>Negative impact on quality of life</li>
                                </ul>
                               <p className="small">This is not an exhaustive list of complications which can arise from shingles. Please speak to your pharmacist, doctor or practice nurse for more information.</p>
                            </div>
                      </div>
                      <div className="sectionWrapper">
                             <Icon><img src= {plus} alt="how to prevent shingles" title="how to prevent shingles" /></Icon>
                            <div className="section">
                               <h1>Can shingles be treated or prevented? </h1>
                               <p className="spaceBtm"><strong>Treatment/Management:<sup>3</sup></strong><br/>
                                If you think you may have shingles, please speak with your doctor as soon as possible. They may prescribe appropriate medicines to help reduce the severity and duration of your symptoms.</p>
                               <p><strong>General advice for managing symptoms:<sup>3</sup></strong></p>
                               <ul className="inset">
                                 <li>Keep the rash clean and dry to reduce the risk of infection</li>
                                 <li>Wear loose-fitting clothing</li>
                                 <li>Use a cool compress a few times a day</li>
                               </ul>
                              <p><strong>Prevention</strong>:<br/>
                                 Shingles can be prevented through vaccination. Speak with your pharmacist, practice nurse or doctor to discuss prevention options.</p>
                            </div>
                      </div>             
                 </div>
           </WhyYouNeedToKnow>
    </Section>
  );
};
